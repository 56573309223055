/* App */

.site-loader {
  opacity: 1;
  z-index: 1000;
  transition: opacity 1.5s ease, transform 0.5s ease;
}

.site-loader.hidden {
  position: fixed;
  opacity: 0;
  transform: translateY(0.5%);
}

/* Default */

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  color: #fff !important;
  font-family: "Centra", sans-serif !important;
}

/* Banner */
.banner {
  margin-top: 10;
  padding: 200px 10px 450px 10px;
  background-image: linear-gradient(
      rgb(250, 250, 250, 0.1),
      rgb(250, 250, 250, 0.05),
      rgb(250, 250, 250, 0.025),
      rgb(250, 250, 250, 0.009)
    ),
    url("./assets/gloves-up-sit-1.png");
  background-position: top;
  background-size: cover;
}

.banner .title {
  font-size: 60px;
  display: flex;
  justify-content: center;
  background: linear-gradient(
    to top,
    rgba(250, 250, 250, 1),
    rgba(250, 250, 250, 0.9),
    rgba(250, 250, 250, 0.8),
    rgba(148, 19, 19, 1)
  );
  color: transparent;
  border-radius: 6px;
  -webkit-background-clip: text;
  background-clip: text;
}

.banner {
  margin-top: 10;
  padding: 200px 10px 450px 10px;
  background-size: cover;
}

.fade-in {
  opacity: 1;
  transition: opacity 1s ease, transform 1s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease, transform 1s ease;
}

.title {
  color: white;
  font-size: 2em;
  text-align: center;
}

/* NavBar */
/* nav.navbar {
  padding: 25px 0;
  position: fixed;
  width: 100%;
  z-index: 98;
  transition: 0.32s ease-in-out;
} */

nav.navbar {
  padding: 25px 0;
  position: fixed;
  width: 100%;
  z-index: 98;
  transition: 0.32s ease-in-out;
  display: flex;
  justify-content: space-between;
}

.navbar-expand-md .navbar-collapse {
  align-self: flex-start;
}

nav.navbar .nav-brand img {
  height: 160px;
  transition: height 0.5s ease-in-out;
  opacity: 0.9;
}

.nav-brand-small img {
  height: 60px;
  transition: height 0.5s ease-in-out;
}

.navbar-toggle {
  background-color: rgb(148, 19, 19);
  padding: 10px !important;
  width: 60vw;
  height: 100vh;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 500;
  color: white;
  letter-spacing: 0.8px;
  padding: 0 10px;
  font-size: 18px;
}

nav.navbar .navbar-nav .nav-link.navbar-link-toggle {
  font-weight: 500;
  color: white;
  letter-spacing: 0.8px;
  padding: 2px 0px 2px 8px;
  font-size: 18px;
  text-align: right;
}

nav.navbar .nav-toggle {
  border: 1px solid rgb(250, 250, 250, 0.3);
  background-color: rgb(250, 250, 250);
  opacity: 0.8;
}

nav.navbar .nav-toggle:hover {
  border: 1px solid rgb(250, 250, 250, 0.3);
  box-shadow: 2px 2px 5px rgba(250, 250, 250, 0.2);
  background-color: rgb(250, 250, 250);
  opacity: 1;
}

.modal {
  background-color: rgb(148, 19, 19);
  /* width: 60vw; */
  max-width: 500px;
}

.modal-content {
  border: none !important;
}

.modal-parent {
  background-color: rgb(148, 19, 19);
  text-align: end;
  cursor: pointer;
  padding: 4px;
}

.modal-dialog {
  border: solid 1px rgb(148, 19, 19);
}

.modal-cancel-btn {
  height: 22px;
  opacity: 0.9;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.modal-cancel-btn:hover {
  transform: scale(1.2);
  opacity: 1;
}

.modal-body {
  background-color: rgb(148, 19, 19);
  /* text-align: end; */
}

.nav-link.navbar-link-small {
  background-color: rgb(148, 19, 19);
  border: none;
  margin: 20px 2px 20px 20px;
  font-size: 20px;
  display: flex;
  justify-content: flex-end;
}

.navbar-link-small:hover .navbar-arrow {
  opacity: 1;
  transform: translateX(2px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.navbar-link-small:hover .navbar-name {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.navbar-name {
  opacity: 0.9;
}

.navbar-arrow {
  opacity: 0;
  height: 28px;
  padding: 0px 0px 0px 5px;
  display: flex;
  align-self: center;
  transform: translateX(-10px);
}

/* Headers */
.title {
  font-size: 60px;
  display: flex;
  justify-content: center;
  background: linear-gradient(
    to top,
    rgba(250, 250, 250, 1),
    rgba(250, 250, 250, 0.5),
    rgba(250, 250, 250, 1)
  );
  border-radius: 6px;
  -webkit-background-clip: text;
  background-clip: text;
  margin-bottom: 20px;
}

/* Classes */

.classes-parent {
  font-size: 18px;
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
  background-color: rgb(148, 19, 19, 0.8);
  padding: 0px 20px 20px 20px;
  align-items: center;
}

.classes-child {
  display: flex;
  justify-content: center;
  width: 450px;
  margin: 10px;
}

.glow-text {
  color: #fff; /* Set text color */
  font-weight: bold;
  text-shadow: 0 0 10px #ff5733, 0 0 20px #ff5733, 0 0 30px #ff5733,
    0 0 40px #ff5733, 0 0 50px #ff5733, 0 0 60px #ff5733, 0 0 70px #ff5733;
  animation: glow 1.5s infinite alternate;

  &:hover {
    .classes-body {
      background-color: aliceblue;
    }
  }
}

@keyframes glow {
  from {
    text-shadow: 0 0 5px #ff5733, 0 0 10px #ff5733, 0 0 15px #ff5733,
      0 0 20px #ff5733, 0 0 25px #ff5733, 0 0 30px #ff5733, 0 0 35px #ff5733;
  }
  to {
    text-shadow: 0 0 10px #ff5733, 0 0 20px #ff5733, 0 0 30px #ff5733,
      0 0 40px #ff5733, 0 0 50px #ff5733, 0 0 60px #ff5733, 0 0 70px #ff5733;
  }
}

.classes-body {
  background-color: rgb(148, 19, 19, 0.8);
  padding-bottom: 50px;
  &.hovered {
    background-color: aliceblue; /* Change background when hovered */
  }
}

.class-arrow {
  opacity: 0;
  display: flex;
  color: white;
  align-self: flex-end;
  width: 120px;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateX(-100%);
}

.class-arrow.hover {
  opacity: 1;
  transform: translateX(0%);
}

.classes-note {
  font-size: 14px;
  text-align: left;
  padding-bottom: 5px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.guide-link {
  color: #0000ee; /* Set text color */
  font-weight: bold;
  // text-shadow: 0 0 10px #ff5733, 0 0 20px #ff5733, 0 0 30px #ff5733,
  //   0 0 40px #ff5733, 0 0 50px #ff5733, 0 0 60px #ff5733, 0 0 70px #ff5733;
  // animation: glow 1.5s infinite alternate;
  &:hover {
    color: #0056b3;
    cursor: pointer;
  }
}

/* Pt */
.pt-body {
  background-color: rgb(148, 19, 19, 0.8);
  padding-bottom: 150px;
}

/* Coaches */
.coaches-body {
  background-color: rgb(148, 19, 19, 0.8);
  padding-bottom: 50px;
}

/* Our Story */

.story-body {
  background-color: rgb(148, 19, 19, 0.8);
  padding-bottom: 50px;
}

.story-content-parent {
  margin-bottom: 100px;
  width: 90%;
}

.story-content {
  margin: 30px;
}

.story-img-col img {
  height: 450px;
  border-radius: 10%;
}

/* Card Coaches */

.coach-row {
  width: 90%;
  margin-bottom: 100px;
}

.coach-parent {
  margin-bottom: 30px;
}

.card.coach {
  display: flex;
  flex-direction: row;
  max-width: 36em;
}

.coach-img {
  margin: auto;
  padding: 20px;
  border-radius: 30px;
}

.coach-contact {
  display: flex;
  flex-direction: column;
}

.coach-insta {
  margin-bottom: 6px;
}

.coach-insta-img {
  height: 22px;
  padding-right: 8px;
}

/* Footer */

.footer {
  background-color: rgba(148, 19, 19, 0.8);
  padding: 10px 2% 10px 2%;
}

.footer .content {
  display: flex;
  flex-direction: column;
}

.footer .content .row-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer .content .row-1 .symbols {
  display: flex;
}

.footer .symbols img {
  opacity: 0.7;
  margin-left: 10px;
}

.footer .symbols img:hover {
  transition: 0.2s ease-in-out;
  opacity: 1;
  cursor: pointer;
}

.row-1-address {
  padding-top: 8px;
  padding-bottom: 4px;
  padding-left: 10px;
  color: rgb(250, 250, 250, 0.9);
  font-size: 10px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
}

.footer .row-2 {
  padding-top: 8px;
  padding-bottom: 4px;
  color: rgb(250, 250, 250, 0.5);
  font-size: 10px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
}

/* Not Found */

.not-found-body {
  background-color: rgb(148, 19, 19, 0.8) !important;
  min-height: 55vh;
}

.not-found-msg {
  text-align: center;
  padding: 25px 0px 50px 0px;
}

/* Cookie Alert */

.cookie-consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  color: white;
  padding: 12px;
  text-align: center;
  font-family: Arial, sans-serif;
  z-index: 1000;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.2);
}

.cookie-consent-div {
  display: flex;
}

.cookie-x-button {
  background-color: rgba(0, 0, 0, 0);
  font-size: 16px;
  color: white;
  border: solid 5px rgba(0, 0, 0, 0);
  width: 2%;
}

.cookie-x-button:hover {
  scale: 1.2;
}

.cookie-message {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.5;
  width: 98%;
}

.accept-cookie-btn {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: rgb(148, 19, 19, 0.8);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.accept-cookie-btn:hover {
  background-color: rgb(148, 19, 19, 1);
}

/* MerchCard */
.merch-body {
  background-color: rgb(148, 19, 19, 0.8);
  padding-bottom: 50px;
}

.merch-card {
  transition: transform 0.3s ease;
  cursor: pointer;
  margin: 20px 4px 4px 4px;
  padding: 20px 20px 20px 20px;
}

.merch-card:hover {
  transform: scale(1.05);
}

.merch-card-body {
  color: white;
  letter-spacing: 0.8px;
  padding: 0 10px;
  font-size: 18px;
  text-align: center;
}

.merch-card-body-name {
  font-weight: 500;
}

.merch-card-body-price {
  font-weight: 300;
}

.merch-card-buy-btn {
  margin: 5px 0px 0px 0px;
  max-width: 100px !important;
}

.merch-card:hover .merch-card-buy-btn {
  background-color: rgb(148, 19, 19);
  border-color: rgb(148, 19, 19);
  color: rgb(255, 255, 255);
}

/* Media Queries */

/* XXL */
@media (max-width: 1400px) {
  .coach-img-col {
    padding-right: 0px;
    padding-left: 22px;
  }
  .coach-parent {
    margin-bottom: 20px;
  }
}

/* XL */
@media (max-width: 1200px) {
  nav.navbar .nav-brand img {
    height: 120px;
    transition: height 0.5s ease-in-out;
  }
}

@media (max-width: 999px) {
  .classes-parent {
    text-align: center;
  }
}

/*  Medium */
@media (max-width: 768px) {
  nav.navbar .nav-brand img {
    height: 100px;
    transition: height 0.5s ease-in-out;
  }
  .card.coach {
    flex-direction: column;
  }
}

/* Small */
@media (max-width: 576px) {
  nav.navbar .nav-brand img {
    height: 60px;
    transition: height 0.5s ease-in-out;
  }
  nav.navbar {
    padding: 15px 0;
  }
  .banner {
    padding: 300px 20px 0px 20px;
    background-image: linear-gradient(
        to top,
        rgba(148, 19, 19, 0.5),
        rgb(250, 250, 250, 0.1),
        rgb(250, 250, 250, 0.07),
        rgb(250, 250, 250, 0.05),
        rgb(250, 250, 250, 0.03)
      ),
      url("./assets/gloves-up-sit-2.png");
  }
  .banner .title {
    font-size: 42px;
    color: white;
    padding: 0px;
  }

  .story-img-col img {
    height: 300px;
    border-radius: 10%;
    padding-top: 20px;
  }

  .navbar-toggle {
    margin-left: calc(90vw - 200px) !important;
  }
  .classes-note {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  .arrow-parent {
    display: flex;
    align-self: flex-end;
    padding: 4px;
  }
}
