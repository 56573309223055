/* Memberships */
.memberships-body {
  background-color: rgb(148, 19, 19, 0.8);
  padding-bottom: 50px;
  min-height: 450px;
}

/* Card Membership*/
.membership {
  padding: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  width: 18rem;
  margin-bottom: 30px;
  transition: transform 0.3s ease;
  cursor: pointer;
  margin-top: 20px;
}

.membership.placeholder {
  background-color: transparent;
}

.membership:hover {
  transform: scale(1.03);
}

.membership-card-title {
  text-align: center;
}

.merch-card-buy-btn {
  margin: 5px 0px 0px 0px;
  max-width: 100px !important;
}

.membership:hover .membership-card-buy-btn {
  background-color: rgb(148, 19, 19, 0.9);
  border-color: rgb(148, 19, 19, 0.9);
  color: rgb(255, 255, 255, 0.5);
  transform: scale(1.01);
}

.membership-img-top {
  height: 200px;
  border-radius: 4px;
}

.membership-card-body {
  padding: 6px;
}

.membership-card-text {
  text-align: center;
  padding-bottom: 0px;
}

.membership-group-item {
  font-weight: 500;
  font-size: 14px;
  padding: 10px 0px 8px 4px;
}
.membership-group-desc {
  font-weight: 300;
  font-size: 16px;
  padding: 10px 0px 8px 4px;
  text-align: center;
}

.membership-group-item img {
  padding-right: 8px;
}

.membership-card-buy {
  display: flex;
  justify-content: center;
}

.card-body-price {
  font-weight: 500;
  font-size: 14px;
  padding: 10px 2px 2px 10px;
  text-align: center;
}

.accordion-container{
  width: 90vw;
  padding-bottom: 50px;
}

.accordion-body {
  background-color: rgb(148, 19, 19, 0.7);
}

.accordion-body-item {
  display: flex;
  justify-content: center;
}
