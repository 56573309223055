// Override Bootstrap primary color variable
$primary: rgb(148, 19, 19);
$secondary: rgb(255, 255, 255);

// Import Bootstrap's SASS files
@import "../../node_modules/bootstrap/scss/bootstrap";

// Define custom fonts
@font-face {
  font-family: Centra;
  src: url("../assets/fonts/CentraNo2-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url("../assets/fonts/CentraNo2-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url("../assets/fonts/CentraNo2-Book.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Bebas;
  src: url("../assets/fonts/BebasNeue-Regular.ttf");
  font-weight: 400;
}

// Apply custom styles
.title {
  font-family: "Bebas", sans-serif !important;
  -webkit-background-clip: text;
  background-clip: text;
}

// BTNs
.btn-primary {
  // Use primary color variable to set CSS custom properties
  --bs-btn-color: #{$secondary};
  --bs-btn-bg: #{$primary};
  --bs-btn-border-color: #{$primary};
  --bs-btn-focus-shadow-rgb: 164, 54, 54;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: gray;
  --bs-btn-disabled-border-color: gray;

  // Add cursor pointer
  cursor: pointer;

  // Define hover state
  &:hover {
    color: #{$secondary};
    background-color: #{shade-color($primary, 10%)};
    border-color: #{shade-color($primary, 10%)};
  }

  // Define active state
  &.btn:first-child:active {
    color: #{$secondary};
    background-color: #{shade-color($primary, 20%)};
    border-color: #{shade-color($primary, 20%)};
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
}

.btn-secondary {
  // Use primary color variable to set CSS custom properties
  --bs-btn-color: #{$primary};
  --bs-btn-bg: #{$secondary};
  --bs-btn-border-color: #{$secondary};
  --bs-btn-focus-shadow-rgb: 164, 54, 54;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: gray;
  --bs-btn-disabled-border-color: gray;

  // Add cursor pointer
  cursor: pointer;

  // Define hover state
  &:hover {
    color: #{$primary};
    background-color: #{shade-color($secondary, 10%)};
    border-color: #{shade-color($secondary, 10%)};
  }

  // Define active state
  &.btn:first-child:active {
    color: #{$primary};
    background-color: #{shade-color($secondary, 20%)};
    border-color: #{shade-color($secondary, 20%)};
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
}
